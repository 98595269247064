.admin_select {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D9D9D9;
    width: 120px;
    height: 80px;
    margin: 15px;
    border-radius: 0px;
    text-align: center;
    text-decoration: none;
    color: black;
    border-radius: 5px;
}

.admin_select .active { 
    background-color: #069942;
}

.admin_select:hover,.admin_select:focus,.admin_select:active, 
.admin_select:active:focus:not(:disabled):not(.disabled){
    box-shadow: none!important;
    outline: 0;
    text-decoration: none;
    color: black;
    
}
.container {
     width: 85%;
     margin: 0 auto;
}

.butns {
    display: grid;
    width: 90%;
    margin: 0 auto;
    grid-template-columns: repeat(8,1fr);
}

@media (max-width: 1520px)
{ 
    .butns {
        grid-template-columns: repeat(4,1fr);
    }

}

@media (max-width: 840px)
{ 
    .butns {
        grid-template-columns: repeat(2,1fr);
    }

}