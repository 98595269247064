.products {
    display: grid;
  justify-content: center;
  grid-template-columns: repeat(3,1fr);
  margin-left: 50px;
}

.container {
  display: flex;
  width: 80%;
  margin: 0px auto;
}


@media (max-width: 1570px)
{
  .products {
    grid-template-columns: repeat(2, 1fr);
  }

}

@media (max-width: 1170px)
{
  .products {
    grid-template-columns: repeat(1, 1fr);
  }

}

@media (max-width: 870px)
{
  .products {
    padding: 0px;
    margin-left: 0px;
  }
  .container{
    flex-direction: column;
    align-items: center;
  }

}
