.first-row-basket {
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #D9D9D9;
    color: #000000;
    align-items: center;
}

.active { 
    background-color: green;
}

.container { 
    width: 75%;
    margin: 0 auto;
}

.number {
    width: 40px;
}
.photo {
    width: 300px;
}

.name {
    width: 40%;
}

.count {
    width: 50px;
}

.basket_form {
    width: 80%;
    margin: 0 auto;
}

.add_order {
    background-color: #069942;
    color: white;
    padding: 10px;
    margin-bottom: 10px;
}

.basket_price {
    width: 70%;
    margin: 0 auto;
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 49px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;

color: #000000;
margin-bottom: 75px;
}

.form_tittle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: calc(25px + 15*((100vw )/1920));
    line-height: 49px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #000000;
    margin-bottom: 40px;
}
.delivery_radio {
    margin-left: 50px;
}

.form_tittle_li {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: calc(15px + 15*((100vw )/1920));
    line-height: 40px;
    /* identical to box height, or 111% */
    
    display: flex;
    align-items: center;
    
    color: #000000;
    margin-bottom: 10px;
}

.form_paragraph {
    margin: 30px 0px;
}

.add_order:hover,.add_order:focus,.add_order:active, 
.add_order:active:focus:not(:disabled):not(.disabled) {
    box-shadow: none!important;
    text-decoration: none;
    color: white;
    outline: none;
}

@media (max-width: 870px)
{
    .container {
        width: 90%;
    }

}