.review_user {
    margin-left: 50px;
    margin-bottom: 50px;
}

.form_review {
}

.input { 
    margin-left: 50px;
}

.text { 
    margin-left: 30px;
    word-wrap: break-word;
    white-space: wrap;
}

.give_review {
    font-family: 'Montserrat';
    font-weight: 400;
   font-size: calc(15px + 5*((100vw )/1920))
}

.review {
    margin-top: 100px;
}

.tittle { 
    font-family: 'Montserrat';
    font-weight: 600;
   font-size: calc(25px + 5*((100vw )/1920))
}

.no_review {
    font-family: 'Montserrat';
    font-weight: 500;
   font-size: calc(15px + 5*((100vw )/1920));
   margin-left: 50px;
   margin-bottom: 50px;
}

.comment {
    display: flex;
}

.success {
    margin-left: auto;
    margin-right: 0em;
    background-color: #4E9443;
    color: #FFFFFF;
    height: 100%;
    border-radius: 0px;
    box-shadow: none!important;
    text-decoration: none;
    border: 0px;
    outline: none;
}

.form_control {
    border-color: #4E9443;
}
.success:hover,.success:focus,.success:active, 
.success:active:focus:not(:disabled):not(.disabled) {
    box-shadow: none!important;
    text-decoration: none;
    color: white;
}


.form_control:hover,.form_control:focus,.form_control:active, 
.form_control:active:focus:not(:disabled):not(.disabled) {
    box-shadow: #4E9443;
    border-color: #4E9443;
}


@media (max-width: 870px)
{
    .comment {
        display: block;
    }
    .text {
        margin-left: 20px;
        width: 200px;
    }
    
    .review {
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }

    .input {
        margin-left: 20px;
    }
    .form_review {
        width: 70%;
    }

}