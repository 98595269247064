.product {
    max-width: 250px;
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.link:hover,.link:focus,.link:active, 
.link:active:focus:not(:disabled):not(.disabled) {
    box-shadow: none!important;
    text-decoration: none;
    color: #000000;
    outline: none;
}
.add_to_cart {
    margin-left: auto;
    margin-right: 0em;
    background-color: #4E9443;
    color: #FFFFFF;
    width: 33%;
    border-radius: 0px;
}

.avatar {
     width: 250px;
     height: 250px;
}

.add_product_container {
    display: flex;
    background: #D9D9D9;
    margin: 0px;
    width: 100%;
}

.minus {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 60px;
    line-height: 49px;
    display: flex;
    color: #000000;
}

.count {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 40px;
    line-height: 49px;
    margin: 0px;
    display: flex;
    color: #000000;
}

.plus {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 60px;
    line-height: 49px;
    color: #000000;
}

.count_and_art {
    display: flex;
}

.countity { 
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #000000;
}

.art { 
    margin-left: 10px;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-left: auto;
    margin-right: 0em;
    color: #000000;
}

.tittle {
    margin-top: 5px;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: calc(20px + 2*((100vw - 320px)/1920));
    line-height: calc(20px + 2*((100vw - 320px)/1920));
    color: #000000;
}

.price {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    color: #000000;
}

@media (max-width: 870px)
{
  .product {
    margin-left: 0px;
    margin-right: 0px;
  }

}