.profile__input {
    border:0px;
    border-radius: 5px;
    box-shadow: 0px 4px 5px #888;
    width: 100%;
    height: 50px;
    background-color: #EBEBEB;
}
.container {
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reg__label {
    margin: 20px 0px 5px 0px;
    font-family: 'Montserrat';
    font-weight: 300;
    color: #000000;
    font-size: 20px;
    line-height: 24px;
}

.form__profile {
    width: 600px;
}

.change_btns {
    box-shadow: 0px 2px 10px #888;
    margin-top: 40px;
    background-color: #4E9443;
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    height: 50px;
    border-radius: 5px;
    
}

.leave_btns {
    border: #4E9443 1px solid;
    width: fit-content;
    margin-top: 40px;
        font-family: 'Montserrat';
    font-weight: 300;
    height: 50px;
    font-size: 20px;
    line-height: 24px;
    color: #4E9443;
    border-radius: 5px;
}


.change_btns:hover,.change_btns:focus,.change_btns:active, 
.change_btns:active:focus:not(:disabled):not(.disabled) {
    box-shadow: none!important;
    text-decoration: none;
    color: white;
    outline: none;
}


.leave_btns:hover,.leave_btns:focus,.leave_btns:active, 
.leave_btns:active:focus:not(:disabled):not(.disabled) {
    box-shadow: none!important;
    text-decoration: none;
    color: #4E9443;
    outline: none;
}

@media (max-width: 670px)
{
    .form__profile {
        width: 300px;
        margin: 0px auto;
    }
    .login_bnts {
        flex-direction: column;
        align-items: start;
    }
}