.container {
    margin-bottom: 100px;
}

.avatar {
    width: 300px;
    height: auto;
}

.table {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}
.collumn {
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: space-between;
    margin: 0px 10px 30px 10px;  
}

.add_category {
    background-color: #069942;
    color: white;
    padding: 10px;
    margin-bottom: 10px;
}

.search {
    max-width: 300px;
    margin-bottom: 10px;
}

@media (max-width: 1800px)
{
    .table { 
        grid-template-columns: repeat(4,1fr);
    }

}

@media (max-width: 1500px)
{
    .table { 
        grid-template-columns: repeat(3,1fr);
    }

}

@media (max-width: 1100px)
{
    .table { 
        grid-template-columns: repeat(2,1fr);
    }

}

@media (max-width: 768px)
{
    .table { 
        grid-template-columns: repeat(1,1fr);
    }

}
