  .grid_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 10px;
  }
  .grid_item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: center;
  }


  @media (max-width: 1570px)
{
  .grid_container {
    grid-template-columns: repeat(2, 1fr);
  }

}

@media (max-width: 1170px)
{
  .grid_container {
    grid-template-columns: repeat(1, 1fr);
  }

}

@media (max-width: 870px)
{
  .grid_container {
    padding: 0px;
  }
  .grid_item {
    padding: 20px 0px;
  }
}