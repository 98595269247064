.container {

    margin-bottom: 100px;
}

.slider { 
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.product_div {
    display: flex;
    flex-wrap: wrap;
}
.avatar {
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.title {
    word-wrap: break-word;
    margin-bottom: 5px;
}

.one_device {
    width: 300px;
    margin: 0px 10px 30px 10px;   
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.product_div { 
    display: grid;
    grid-template-columns: repeat(5,1fr);
    
}

.search {
    max-width: 300px;
    margin-bottom: 10px;
}

.add_product {
    background-color: #069942;
    color: white;
    padding: 10px;
    margin-bottom: 10px;
}

.select {
    max-width: 300px;
    margin-bottom: 20px;
}

@media (max-width: 1800px)
{
    .product_div, .slider { 
        grid-template-columns: repeat(4,1fr);
    }

}

@media (max-width: 1500px)
{
    .product_div, .slider { 
        grid-template-columns: repeat(3,1fr);
    }

}

@media (max-width: 1100px)
{
    .product_div, .slider { 
        grid-template-columns: repeat(2,1fr);
    }

}

@media (max-width: 768px)
{
    .product_div, .slider { 
        grid-template-columns: repeat(1,1fr);
    }

}
