.product_in_basket {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr 2fr;
    padding: 0px 0px 30px 0px;
}

tr.product_in_basket>td {
    padding-bottom: 30px;
}


  .product {
    display: flex;
    padding: 30px;
    margin: 50px 0px;
}
.add_to_cart {
    box-shadow: 0px 2px 10px #888;
    margin-top: 40px;
    background-color: #4E9443;
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    height: 50px;
    border-radius: 5px;
}
.number {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
}
.avatar__product {
 width: 250px;
 height: 250px;
 object-fit: contain;
}

.category_product {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: calc(10px + 5*((100vw )/1920));
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #555555;
}
.name_product {
    width: 300px;
}
.price_td {
    display: flex;
    justify-content: space-between;
    margin-left: -100px;
}
.title_product {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: calc(13px + 5*((100vw )/1920));
    line-height: 39px;
    display: flex;
    align-items: center;
    text-align: center;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #000000;
    margin-bottom: 80px;
    width: 90%;

}
.code_product {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    margin-right: 10px;
}
.code_and_heart {
    display: flex;
}
.follow_heart {
    width: 30px;
    height: 30px;
}

.price_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.price {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    white-space: nowrap;
}

.add_product_container {
    display: flex;
    background: #D9D9D9;
    margin: 0px;
    padding: 0px 10px;
    width: 180px;
    justify-content: space-between;
    align-items: center;
}

.minus {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 49px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #000000;
}

.count {
font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 49px;
    margin: 0px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #000000;
}

.plus {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 49px;
    /* identical to box height */
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
}

.available {
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
/* identical to box height */

display: flex;
align-items: center;
justify-content: center;
text-align: center;
text-decoration-line: underline;

color: #000000;
margin-top: 5px;
}


@media (max-width: 1450px)
{
    .price_td {
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        margin-left: 0px;
    }

    .title_product {
        margin-bottom: 20px;
    }

}

@media (max-width: 1100px)
{
    .price_td {
        flex-direction: row;
        align-items:center;
        justify-content: space-around;
        margin-left: 30px;
    }
    .product_in_basket {
        grid-template-columns: 1fr 3fr 2fr;
    }
    .price_td {
        grid-row: 2/2;
        grid-column: 2/4;
    }

    .title_product {
        margin-bottom: 20px;
    }

}

@media (max-width: 750px)
{
    .price_td {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-left: 0px;
        grid-row: 3/3;
        grid-column: 1/3;
    }
    .product_in_basket {
        grid-template-columns: 1fr 6fr;
        align-items: center;

    }

    .name_product {
        grid-row: 2/2;
        grid-column: 2/3;
        align-items: center;
    }
    .avatar__product {
        width: 200px;
        height: 200px;
    }

}

@media (max-width: 450px)
{
    .price_td {
        flex-direction: column;
    }
    .price {
        margin-top: 10px;
    }
}