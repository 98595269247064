.container {
    margin-bottom: 100px;
}
.user {
    display: grid;
    grid-template-columns: repeat(5,1fr);
}

.search {
    width: 300px;
}

@media (max-width: 1800px)
{
    .user { 
        grid-template-columns: repeat(4,1fr);
    }

}

@media (max-width: 1500px)
{
    .user { 
        grid-template-columns: repeat(3,1fr);
    }

}

@media (max-width: 1100px)
{
    .user { 
        grid-template-columns: repeat(2,1fr);
    }

}

@media (max-width: 768px)
{
    .user { 
        grid-template-columns: repeat(1,1fr);
    }

}